import axios from "axios";

const api = axios.create({
  baseURL: "https://thyrst-login.builtwithdark.com/",
});

const normalizeError = (error: any) => {
  if (error.response) {
    throw new Error(error.response.data);
  } else if (error.request) {
    throw new Error(error.request);
  } else {
    throw new Error(error.message);
  }
};

export const createEntry = async (wallet: string) => {
  try {
    return await api.post("/entry", {
      wallet: wallet,
    });
  } catch (error) {
    return normalizeError(error);
  }
};

export const getEntry = async (entryCode: string) => {
  try {
    return await api.get("/entry/" + entryCode);
  } catch (error) {
    return normalizeError(error);
  }
};

export const createSession = async (entryCode: string, signature: string) => {
  try {
    return await api.post("/session", {
      entry_code: entryCode,
      signature: signature,
    });
  } catch (error) {
    return normalizeError(error);
  }
};

export const checkSession = async (wallet: string, secret: string) => {
  try {
    return await api.post("/check/" + wallet, {
      secret: secret,
    });
  } catch (error) {
    return normalizeError(error);
  }
};
