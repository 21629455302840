import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { injected } from "../libs/connectors";

export function WalletConnector() {
  const { account, active, error, activate } = useWeb3React();

  const connect = async () => {
    try {
      await activate(injected);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized && !active && !error) {
        activate(injected);
      }
    });
  }, [activate, active, error]);

  const account_connected = <span>{account}</span>;
  const account_disconnected = (
    <button type="button" onClick={connect}>
      Connect
    </button>
  );

  return (
    <div>
      <div>Wallet: {active ? account_connected : account_disconnected}</div>
      {error && (
        <div style={{ color: "red" }}>
          <span style={{ fontWeight: "bold" }}>Error! </span>
          <span>{error.message}</span>
        </div>
      )}
    </div>
  );
}
