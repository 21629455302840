import Home from "./Home";
import Login from "./Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { WalletConnector } from "./WalletConnector";

function getLibrary(provider: any): Web3Provider {
  return new Web3Provider(provider);
}

function NoMatch() {
  return <div>Not found.</div>;
}

export default function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div>
        <h1>Dark Login</h1>
        <WalletConnector />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="login/:entryCode" element={<Login />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Router>
      </div>
    </Web3ReactProvider>
  );
}
