import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../libs/connectors";
import { createSession, getEntry } from "../libs/api";

const getLoginMessage = async (entryCode: string) => {
  const result = await getEntry(entryCode);
  return result.data.message;
};

const signLoginMessage = async (library: any, message: string) => {
  const signer = library.getSigner();
  return await signer.signMessage(message);
};

const confirmLogin = async (entryCode: string, signature: string) => {
  return await createSession(entryCode, signature);
};

export default function Login() {
  const { entryCode } = useParams();
  const { account, active, error, activate, library } = useWeb3React();
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!active && !error) activate(injected);
  }, [active, error, activate]);

  useEffect(() => {
    setLoginSuccess(false);
    setLoginError(null);
    if (active && library && entryCode) {
      (async () => {
        try {
          const message = await getLoginMessage(entryCode);
          const signature = await signLoginMessage(library, message);
          await confirmLogin(entryCode, signature);
          setLoginSuccess(true);
        } catch (e: any) {
          setLoginError(e.message);
        }
      })();
    }
  }, [active, account, entryCode, library]);

  return (
    <div>
      {loginSuccess && (
        <div style={{ color: "green" }}>You have been successfully logged in. You may close this window now.</div>
      )}
      <div>{active || "You must connect your wallet."}</div>
      <div style={{ color: "red" }}>{loginError || ""}</div>
      <div>
        <Link to="/">Home</Link>
      </div>
    </div>
  );
}
